import { useFetchers, useRouteLoaderData } from '@remix-run/react'
import type { UserPreferencesLoaderData } from './settings.types'
import { COMPLIANCE_CHART, SCREEN, USER_PREFERENCES } from '~/modules/settings'

export function useUserPreferences() {
  return useRouteLoaderData('root') as UserPreferencesLoaderData
}

export function useScreen() {
  const { userPreferences } = useUserPreferences()
  const fetchers = useFetchers()
  const fetcher = fetchers.find(
    (fetcher) => fetcher.formData?.get('preferenceName') === USER_PREFERENCES.Screen,
  )
  const optimistic = fetcher?.formData?.get('preferenceOption')
  const screen = optimistic ?? userPreferences.screen ?? SCREEN.FullWidth

  return { screen, isFullWidth: screen === SCREEN.FullWidth }
}

export function useToggleComplianceChart() {
  const { userPreferences } = useUserPreferences()
  const fetchers = useFetchers()
  const fetcher = fetchers.find(
    (fetcher) => fetcher.formData?.get('preferenceName') === USER_PREFERENCES.HideChartOnTimesheet,
  )
  const optimistic = fetcher?.formData?.get('preferenceOption')
  const isComplianceChartHidden =
    optimistic ?? userPreferences.isComplianceChartHidden ?? COMPLIANCE_CHART.False

  return typeof isComplianceChartHidden === 'string'
    ? isComplianceChartHidden === COMPLIANCE_CHART.True
    : (isComplianceChartHidden as boolean)
}
