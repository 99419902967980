export enum SCREEN {
  Centered = 'Centered',
  FullWidth = 'Full Width',
}

export enum THEME {
  Dark = 'Dark',
  Light = 'Light',
}

export enum COMPLIANCE_CHART {
  True = 'true',
  False = 'false',
}

export enum USER_PREFERENCES {
  Screen = 'Screen',
  Theme = 'Theme',
  HideChartOnTimesheet = 'HideChartOnTimesheet',
}
